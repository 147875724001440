import React from "react"
import Layout from "../components/Layout"
import "../css/projects/web-project/web_proj1.css"
import { Link } from "gatsby"
import { FaCode, FaLink } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const web_proj6 = () => {
  return (
    <Layout>
      <SEO
        title="Calculator"
        description="Awesome JavaScript (Web-Dev) project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/webprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="py-img-6">
          <div className="py-1-heading">
            <h1>Calculator</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/JavaScript-Calculator"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>
        <div className="git">
          <a
            href="https://arpitsomani8.github.io/JavaScript-Calculator/"
            target="_blank"
            rel="noopener noreferrer"
            title="View live"
          >
            <FaLink className="git-btn"></FaLink>
          </a>
        </div>
        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It:</h3>
            <p>
              This is a javaScript based practice project.
              <br />
              You will find a simple and colorfully designed calculator.
              <br />
              You will also notice the color-varying background, which gives
              amazing look in this project.
              <br />
            </p>

            <h3>Skills Behind It:</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Html5</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>CSS3</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>JavaScript</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default web_proj6
